import request from '@/plugins/axios'
import * as Interface from './permissions.d'


/** S 基础设置 **/

// 获取平台信息
export const apiBaseConfig = () => request.get('/settings.platform/getBaseConfig')

// 设置平台信息
export const apiBaseConfigEdit = (params: any) => request.post('/settings.platform/setBaseConfig', params)

// 获取平台备案信息
export const apiRecordConfig = () => request.get('/settings.platform/getRecordConfig')

// 设置平台备案信息
export const apiRecordConfigEdit = (params: any) => request.post('/settings.platform/setRecordConfig', params)

// 获取关于我们
export const apiGetAbout = () => request.get('/settings.platform/getAbout')

// 设置关于我们
export const apiSetAbout = (params: any) => request.post('/settings.platform/setAbout', params)

/** S 基础设置 **/




/** S 客服设置 **/
// 客服获取
export const apiServiceGet = () => request.get('/settings.service/getConfig')

// 客服设置
export const apiServiceSet = (params: any) => request.post('/settings.service/setConfig', params)
/** E 客服设置 **/




/** S 存储设置 **/
// 获取存储引擎列表
export const apiStorageList = (): Promise<any> =>
  request.get('/settings.Storage/lists')

// 获取存储配置信息
export const apiStorageIndex = (params:any): Promise<any> =>
  request.get('/settings.Storage/index', { params })

// 更新配置
export const apiStorageSetup = (params:any): Promise<any> =>
  request.post('/settings.Storage/setup', params)

// 切换默认存储引擎
export const apiStorageChange = (params:any): Promise<any> =>
  request.post('/settings.Storage/change', params)
/** E 存储设置 **/




/** S 安全设置 **/
// 修改管理员密码
export const apiResetPassword = (params: any) => request.post('/Login/resetPassword', params)
/** E 安全设置 **/



/** S 腾讯地图设置 **/

// 或低地图密钥
export const apiGetMapKey = () => request.get('/settings.map/getMapKey')

// 设置地图密钥
export const apiSetMapKey = (params: any) => request.post('/settings.map/setMapKey', params)

/** E 腾讯地图设置 **/



/** S 管理员 **/
// 管理员列表
export const apiAdminList = (params: any): Promise<any> =>
    request.get('/auth.admin/lists', { params })

// 管理员详情
export const apiAdminDetail = (
    params: any
): Promise<any> => request.get('/auth.admin/detail', { params })

// 添加管理员
export const apiAdminAdd = (data: any): Promise<any> =>
    request.post('/auth.admin/add', data)

// 删除管理员
export const apiAdminDelete = (data: any): Promise<any> =>
    request.post('/auth.admin/delete', data)

// 编辑管理员
export const apiAdminEdit = (data: any): Promise<any> =>
    request.post('/auth.admin/edit', data)

/** E 管理员 **/



/** S 角色 **/
// 角色列表
export const apiRoleList = (params?: any): Promise<any> =>
    request.get('/auth.role/lists', { params })

// 角色详情
export const apiRoleDetail = (
    params: any
): Promise<any> => request.get('/auth.role/detail', { params })

// 添加角色
export const apiRoleAdd = (data: any): Promise<any> =>
    request.post('/auth.role/add', data)

// 编辑角色
export const apiRoleEdit = (data: any): Promise<any> =>
    request.post('/auth.role/edit', data)

// 删除角色
export const apiRoleDelete = (data: any): Promise<any> =>
    request.post('/auth.role/delete', data)

// 权限菜单
export const apiAuthMenu = () => request.post('/config/getMenu')
/** E 角色 **/



/** S 通知设置 **/
// 通知列表
export const apiNoticeData = (params: any) => request.get('/notice.notice/settingLists', { params })

// 通知设置详情
export const apiNoticeDetail = (params: any) => request.get('/notice.notice/detail', { params })

// 通知设置
export const apiNoticeSet = (params: any) => request.post('/notice.notice/set', params)

// 获取短信设置列表
export const apiSmsGetConfig = () => request.get('/notice.sms_config/getConfig')

// 获取短信设置列表
export const apiSmsGetConfigDetail = (params: any) =>
    request.get('/notice.sms_config/detail', { params })

// 通知设置
export const apiSmsSet = (params: any) => request.post('/notice.sms_config/setConfig', params)
/** E 通知设置 **/



// 获取系统日志列表
export const apiSystemlogList = (params: any) => request.get('/settings.log/lists', { params })

// 清除系统缓存
export const apiSystemCacheClear = () => request.post('/settings.cache/clear')

// 定时任务列表
export const apiCrontabLists = () => request.get('/crontab.crontab/lists')

// 添加定时任务
export const apiCrontabAdd = (params: any) => request.post('/crontab.crontab/add', params)

// 查看详情
export const apiCrontabDetail = (params: any) => request.get('/crontab.crontab/detail', { params })

// 编辑定时任务
export const apiCrontabEdit = (params: any) => request.post('/crontab.crontab/edit', params)

// 删除定时任务
export const apiCrontabDel = (params: any) => request.post('/crontab.crontab/delete', params)

// 获取规则执行时间
export const apiCrontabExpression = (params: any) => request.get('/crontab.crontab/expression', { params })

// 操作定时任务
export const apiSrontabOperate = (params: any) => request.post('/crontab.crontab/operate', params)

/** E 系统维护 **/


/** S 系统更新 **/
// 系统更新列表
export const apiSystemUpgradeLists = (params: any) => request.get('/settings.upgrade/lists')

// 下载更新包
export const apiSystemUpgradeDownloadPkg = (params: any) => request.post('/settings.upgrade/downloadPkg', params)

// 一键更新
export const apiSystemUpgrade = (params: any) => request.post('/settings.upgrade/upgrade', params)
/** E 系统更新 **/

// 系统环境
export const apiSystemSystemEnv = () => request.get('/settings.env/systemEnv')


/** S 支付配置 **/
// 设置支付配置
export const apiPaySetKdConfig = (params: any) => request.post('/settings.PayConfig/setKdConfig', params)
/** E 系统更新 **/

// 获取支付配置
export const apiPayGetKdConfig = () => request.get('/settings.PayConfig/getKdConfig')
/** E 支付配置 **/